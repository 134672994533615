<style lang="scss" scoped></style>

<template>
	<div></div>
</template>

<script>
export default {
	name: 'fixedCodeIndex',
	data() {
		return {};
	},
	created() {
		// 后台直接做重定向处理
		this.go();
	},
	methods: {
		// 兼容老的二维码，如果地址中没有参数 payType 能进来这里的，就是个人支付投保页面。渠道支付投保页面，会根据路由直接进入投保页面，无法进入这里
		// 参数中有openWelcome='1' 重定向到openWelcome页面
		// 没参数，重定向到投保页面
		go() {
			let query = this.$route.query;
			if (query) {
				if (!Object.hasOwnProperty.call(query, 'payType')) {
					// 老的二维码，能到这里，就是个人支付的投保页面（渠道支付，会直接进入渠道支付投保页面）
					// 前往 个人支付投保页面
					this.$router.replace({ path: 'indexOneselfPay', query });
				} else {
					if (Object.hasOwnProperty.call(query, 'openWelcome') && query.openWelcome === '1') {
						// 前往签名页面
						this.$router.push({ path: 'welcome', query });
					} else {
						// 前往投保页面
						if (query.payType === '1') {
							// 非渠道支付
							this.$router.replace({ path: 'indexOneselfPay', query });
						} else if (query.payType === '2') {
							// 渠道支付
							this.$router.replace({ path: 'fixedCodeInsUserPay', query });
						}
					}
				}
			}
		},
	},
};
</script>
